import React from "react"
import { SEO } from "@pixicommerce/module-components"
import PageNotFound from "components/PageNotFound"

const ErrorPage = () => {
  return (
    <>
      <SEO title="common.title" />
      <PageNotFound />
    </>
  )
}

export default ErrorPage
