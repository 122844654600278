/* eslint-disable */
import React from "react"
import {
  Svg,
  Link,
  Container,
  FormattedMessage,
} from "@pixicommerce/module-components"

import { errorImg } from "assets/images"

import "./page-not-found.scss"

const PageNotFound = props => {
  return (
    <Container>
      <div className="error-page-section">
        <div className="error-img">
          <Svg width="500" height="372.61" src={errorImg} />
        </div>
        <div className="error-info">
          <FormattedMessage
            id={"error.info"}
            defaultMessage={
              "We’re sorry the page you request could not be found please go back to the homepage"
            }
          />
        </div>
        <div className="error-btn">
          <Link to="/">
            <FormattedMessage id={"homepage.btn"} defaultMessage={"Go Home"} />
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default PageNotFound
